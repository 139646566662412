<template>
  <div>
    <date-picker
      class='w-full date-picker-auto-width'
      input-class='border py-2 px-2 rounded block w-full'
      v-model='dateValue'
      valueType="format"
      :placeholder='placeholder'>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: 'EditFormFieldDate',
  components: {
    DatePicker,
  },
  props: [
    'metaData'
  ],
  data () {
    return {
      dateValue: '',
    }
  },
  watch: {
    dateValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:dateValue', newVal)
        }
      }, 
      immediate: true
    },
  },
  computed: {
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
  }
}
</script>
